
jQuery(function ($) {

  // Disable submit button if the mandatory field are not all filled. Don't forget to add a style for:
  // `input[type="submit"].disabled, input[type="submit"].button-disabled, input[type="submit"]:disabled`
  $('form[id^="gform_"]').on('input', function (e) {
    var $reqd = $(this).find('.gfield_contains_required.gfield_visibility_visible').filter(function (i, c) {
      return []
        .concat($(c).find('input[type="text"], textarea').filter(function (i, fl) { return $(fl).val().length === 0; }).get())
        .concat($(c).find('input[type="checkbox"]').not(':checked').get())
        .length;
    });
    if ($reqd.length) {
      $(this).find('input[type="submit"]').addClass('disabled button-disabled').attr('disabled', 'disabled');
    } else {
      $(this).find('input[type="submit"]').removeClass('disabled button-disabled').removeAttr('disabled');
    }
  }).trigger('input');


  fields_are_initialized = false;

  function change_form_label(label, input) {

    if ('' !== input.val()) {
      $(label).parents('.gfield').addClass('filed');
    }

    $(label).on('click', function () {
      $(input).focus();
    });

    $(input)
      .on('focus', function () {
        $(label).parents('.gfield').addClass('focus');
      })
      .on('focusout', function () {
        var value = $.trim($(this).val());
        $(label).parents('.gfield').addClass('filed');
        $(label).parents('.gfield').removeClass('focus');
        if (value.length === 0 || $(this).val() === '(___) ___-____') {
          $(label).parents('.gfield').removeClass('focus');
          $(label).parents('.gfield').removeClass('filed');
        }
      });
  }

  function add_form_events() {

    $('.gfield:not(.gfield--radio):not(.gfield--select)').each(function () {
      var $field = $(this);

      if ($field.hasClass('initialized')) {
        return;
      }

      var classesExcluded = ['ginput-wrapper--radio', 'ginput-wrapper--checkbox', 'ginput-wrapper--consent', 'ginput-wrapper--select'];

      for (var i = 0; i < classesExcluded.length; i++) {
        if ($field.hasClass(classesExcluded[i])) {
          return;
        }
      }

      var $label;
      var $input;

      $field.addClass('initialized');

      $label = $field.find('.gfield_label');
      $input = $field.find('.ginput_container input , .ginput_container textarea');
      change_form_label($label, $input);

      if ($field.hasClass('gfield--has-multiple-inputs')) {
        $field.find('.ginput_complex > span').each(function () {
          var $deeper_field = $(this);

          if ($deeper_field.hasClass('initialized')) {
            return;
          }

          $deeper_field.addClass('initialized');

          $label = $deeper_field.find('label');
          $input = $deeper_field.find('input, textarea');
          change_form_label($label, $input);
        });
      }
    });
  }


  $(document).on('gform_post_render', function (event, form_id, current_page) {
    fields_are_initialized = true;
    add_form_events();
  });

  // ON READY
  $(function () {

    if (!fields_are_initialized) {
      // Initialize fields events only if they were not already.
      add_form_events();
    }
  });





  if ( document.querySelector('.gfield--fileupload input[type=file]')) {
    var file = document.querySelector('.gfield--fileupload input[type=file]');
    file.addEventListener('change', (e) => {
      // Get the selected file
      const [file] = e.target.files;
      // Get the file name and size
      const { name: fileName, size } = file;
      // Convert size in bytes to kilo bytes
      const fileSize = (size / 1000).toFixed(2);
      // Set the text content
      const fileNameAndSize = `${fileName} - ${fileSize}KB`;

      document.querySelector('.gfield--fileupload .gfield_label').textContent = fileNameAndSize;
    });
  }

});