(function($) {
  
  function toggle_dropdown(e) {
    
    // On quite maintenant si ce n'est pas un click ou la touche ENTER
    if( 'undefined' !== typeof e.keyCode && 13 !== e.keyCode ) {
      return true;
    }
    
    e.preventDefault();
    
    var $submenu_toggle = $(this);
    var $li = $submenu_toggle.parent('.dropdown');
    var $submenu = $submenu_toggle.next('.dropdown-menu');
    
    if($li.hasClass('opened')){
      $li.removeClass('opened');
      $submenu.slideUp('fast');
      
    } else {
      $li.addClass('opened');
      $submenu.slideDown('fast');
    }
  }
  
  function prepare_dropdown_for_nav( $nav ) {
    
    ///// INIT SLIDER POSITION /////
    $nav.find(".active").addClass("opened");
    
    $nav.find(".dropdown").filter(":not(.opened)").each(function() {
      $(this).children('.dropdown-menu').slideUp(0);
    });
    
    
    ///// INIT EVENTS /////
    $nav.find(".dropdown-menu-toggle")
      .on('click' , toggle_dropdown)
      .keypress(toggle_dropdown);
  }
  
  
  function init_menu_mobile() {
    
    var $mobile_menu = $('#mobile-menu');
    
    $('.vtx-burger-container').on('click', function() {

      $('body').toggleClass("mobile-menu-open");
      
    });
    
    prepare_dropdown_for_nav( $mobile_menu.find(".nav-mobile") );    
  }
  
  
  $(document).ready(function(){
    
    init_menu_mobile();

  });

})(jQuery);