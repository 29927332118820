/**
 * Reference accessible navigation: https://adrianroselli.com/2019/06/link-disclosure-widget-navigation.html
 */
(function ($) {
  
  // This selector also need to be changed in the _dropdown-menu.scss
  var dropdown_selector = '.dropdown';
  var dropdown_submenu_wrapper = '.dropdown__submenu-wrapper';
  var dropdown_toggle_selector = '.dropdown__toggle';
  var active_class = 'opened';
  
  var opened_submenu = {};
  
  var unique_name_count = 0;
  
  
  function create_unique_submenu_name() {
    unique_name_count++;
    
    return 'submenu_' + unique_name_count;
  }
  
  
  function try_closing_submenu( e ) {
    for ( var submenu_name in opened_submenu ) {
      if( opened_submenu[ submenu_name ] && ! opened_submenu[ submenu_name ].find( e.target ).length ) {
        /* jshint ignore:start */
        close_submenu( submenu_name );
        /* jshint ignore:end */
      }
    }
  }
  
  
  function get_opened_submenu_by_name( submenu_name ) {
    if( ! opened_submenu[ submenu_name ] ) {
      console.log( submenu_name + ' n\'existe pas encore. (get_opened_submenu_by_name())' );
      return $();
    }
    
    return opened_submenu[ submenu_name ];
  }
  
  function get_submenu_name( $submenu ) {
    
    var submenu_name = $submenu.data( 'sub-menu' );
    
    if( ! submenu_name ) {
      submenu_name = create_unique_submenu_name();
      $submenu.data( 'sub-menu', submenu_name );
    }
    
    return submenu_name;
  }
  
  
  function set_opened_submenu( $submenu ) {
    
    var submenu_name = get_submenu_name( $submenu );
    
    opened_submenu[ submenu_name ] = $submenu;
  }
  
  
  function open_submenu( submenu_name ) {
    
    var $submenu = get_opened_submenu_by_name( submenu_name );
    $submenu.addClass( active_class );
    
    setTimeout( function () {
      this.find( 'li a' ).first().focus();
      var $toggle = this.find( dropdown_toggle_selector ).first();
      $toggle
        .attr( 'aria-expanded', true )
        .attr( 'aria-label', $toggle.data( 'label-close' ) );
    }.bind( $submenu ), 10);
    
    $( document )
      .on( 'click.closing_submenu', try_closing_submenu )
      .on( 'focusin.closing_submenu', try_closing_submenu );
  }
  
  
  function close_submenu( submenu_name ) {
    
    var $submenu = get_opened_submenu_by_name( submenu_name );
    $submenu.removeClass( active_class );
    
    var $toggle = $submenu.find( dropdown_toggle_selector ).first();
    $toggle
    .attr( 'aria-expanded', false )
      .attr( 'aria-label', $toggle.data( 'label-open' ) );
    
    delete opened_submenu[ submenu_name ];
    
    // Supprimer les événements si il n'y a plus de menu ouvert.
    if( ! Object.keys( opened_submenu ).length ) {
      $( document )
        .off( 'click.closing_submenu' )
        .off( 'focusin.closing_submenu' );
    }
  }
  
  
  /////////////// ON READY /////////////////
  $( function () {
    
    $( dropdown_toggle_selector ).on( 'click', function() {
      
      var $dropdown = $( this ).closest( dropdown_selector );
      
      if( ! $dropdown.hasClass( active_class ) ) {
        
        // On pourrait le faire tout le temps, mais en théorie on a déjà set un sub menu au close.
        // C'est donc plus performant de le faire juste ici.
        set_opened_submenu( $dropdown );
        
        open_submenu( get_submenu_name( $dropdown ) );
      } else {
        close_submenu( get_submenu_name( $dropdown ) );
      }
      
    });
  });
})(jQuery);