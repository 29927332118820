// Get the header
var header = document.querySelector('.main-header');
var sticky = header.offsetTop;


// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function stickyHeader() {
  if (window.pageYOffset > sticky ) {
    header.classList.add("sticky");
    
  } else {
    header.classList.remove("sticky");
   
  }
}

// When the user scrolls the page, execute stickyheader
window.onscroll = function() {stickyHeader();};

// toggle search bar in menu 
var hasClicked = false; // disable search on first click
var searchButton = document.querySelector( ".nav-icon .search__submit"); 
var searchClose = document.querySelector( ".nav-icon .search__close"); 
var searchForm = document.querySelector( ".nav-icon .search"); 
var searchInput = document.querySelector(".search__input");
var mainNav = document.querySelector(".nav-primary");

mainNav.classList.remove('search-open');
searchButton.onclick = function(e) {
    if (hasClicked === false) {
        e.preventDefault();
        hasClicked = true;
        mainNav.classList.add('search-open');
        searchForm.classList.add('visible');
        searchInput.focus();
    }
};

searchClose.onclick = function(e) {
  if (hasClicked === true) {
      e.preventDefault();
      hasClicked = false;
      mainNav.classList.remove('search-open');
      searchForm.classList.remove('visible');
  }
};